import "./App.css";
import { Layout } from "./Components/Layout/Layout";

function App() {
  return (
    <>
      <Layout /> 
    </>
  );
}


export default App;
