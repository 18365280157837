export const memberData = [
    {
        id:1, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:2, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:3, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:4, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:5, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:6, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:7, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:8, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:9, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:10, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:11, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:12, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:13, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:14, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:15, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:16, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:17, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:18, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:19, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    },
    {
        id:20, 
        name: 'Habib Kamaldeen',
        role: 'Founder & CEO',
        desc: "Lorem ipsum dolor sit amet consectetur. Laoreet cras turpis hendrerit a diam tristique scelerisque laoreet. Iaculis congue dignissim vitae ligula ultrice",
        facebook_link: 'https://socialmediawebsite.com',
        instagram_link: 'https://socialmediawebsite.com',
        twitter_link: 'https://socialmediawebsite.com',
        linkedin_link: 'https://socialmediawebsite.com',
        email_link: 'https://socialmediawebsite.com'
    }

];